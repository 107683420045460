<template>
  <div class="flex-column">
    <van-nav-bar
      title="业务查询"
      left-text="返回"
      @click-left="back"
      left-arrow
    />
    <van-cell>
      <template #title>
        <div>
          <!--日期范围-->
          <div>
            <span style="color: #888">日期范围：</span>
            <div
              :class="'my_tag' + (daysIndex == 0 ? ' active' : '')"
              @click="setRecentDays(0, 0)"
              size="large"
            >
              今天
            </div>
            <div
              :class="'my_tag' + (daysIndex == 1 ? ' active' : '')"
              @click="setRecentDays(1, 0)"
              size="large"
              style="margin-left: 7px"
            >
              昨天
            </div>
            <div
              :class="'my_tag' + (daysIndex == 2 ? ' active' : '')"
              @click="setRecentDays(2, 7)"
              size="large"
              style="margin-left: 7px"
            >
              近7天
            </div>
            <div
              :class="'my_tag' + (daysIndex == 3 ? ' active' : '')"
              @click="setRecentDays(3, 30)"
              size="large"
              style="margin-left: 7px"
            >
              近30天
            </div>
          </div>
          <div style="margin-top: 7px">
            <span class="dateField" @click="editDate(0, startDate)">
              {{ startDate }}
              <van-icon
                :name="require('../../assets/img/date.png')"
                style="vertical-align: middle; margin: 0 0.3em"
              />
            </span>
            到
            <span class="dateField" @click="editDate(1, endDate)">
              {{ endDate }}
              <van-icon
                :name="require('../../assets/img/date.png')"
                style="vertical-align: middle; margin: 0 0.3em"
              />
            </span>
            <span style="float: right; color: #3a81e1"
              >医院：{{ hospitalNum }}家</span
            >
          </div>
          <div style="margin-top: 7px; display: flex; align-items: center;">
            <div style="flex: 1; display: flex; align-items: center;" >
              <div>医院：</div>
              <div style="flex: 1; width: 1px; margin-right: 14px; border: 0.02667rem solid #bbb; padding: 2px; margin-right: 5px; border-radius: 5px;">
                <van-field
                  v-model="hospitalName"
                  label=""
                  placeholder="请输入"
                  input-align="left"
                  clearable
                  style="width: 100%; padding: 0;"
                />
              </div>
            </div>
            <button @click="getProject">查询</button>
          </div>
          <div style="line-height: 2; margin-top: 0.5em">
            <div class="flex-row color1">
              <div>处方总数：</div>
              <div class="flex1 tar">{{ totalBill }}张</div>
              <div class="color2" style="margin-left: 2em">处方代煎：</div>
              <div class="flex1 tar color2">{{ daijianNum }}张</div>
            </div>
            <div class="flex-row color1">
              <div>处方金额：</div>
              <div class="flex1 tar">{{ vatFreight }}万</div>
              <div class="color3" style="margin-left: 2em">处方代配：</div>
              <div class="flex1 tar color3">{{ daipeiNum }}张</div>
            </div>
          </div>
        </div>
        <van-popup v-model="showPicker" position="bottom">
          <van-datetime-picker
            v-model="curDate"
            type="date"
            title="选择年月日"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="setDate"
            @cancel="showPicker = false"
          />
        </van-popup>
      </template>
    </van-cell>

    <div class="flex1">
      <div v-if="list.length > 0">
        <van-cell
          v-for="(item, index) in list"
          :key="index"
          style="margin-top: 10px"
        >
          <template #title>
            <div>
              <div>
                <div style="font-size: 0.5rem; display: inline">
                  {{ index + 1 }}.&nbsp;&nbsp;{{ item.hospitalNickname }}
                </div>
              </div>

              <div style="line-height: 2; margin-top: 0.5em">
                <div class="flex-row color1">
                  <div>处方总数：</div>
                  <div class="flex1 tar">{{ item.totalNumber }}张</div>
                  <div class="color2" style="margin-left: 2em">处方代煎：</div>
                  <div class="flex1 tar color2">{{ item.daijian }}张</div>
                </div>
                <div class="flex-row color1">
                  <div>处方金额：</div>
                  <div class="flex1 tar">{{ item._vatFreight }}万</div>
                  <div class="color3" style="margin-left: 2em">处方代配：</div>
                  <div class="flex1 tar color3">{{ item.daipei }}张</div>
                </div>
              </div>
            </div>
          </template>
        </van-cell>
      </div>
      <div v-if="list.length == 0" class="noData">暂无数据</div>
    </div>
  </div>
</template>

<script>
import { getLoginInfo } from "../../utils";
export default {
  data() {
    return {
      daijianNum: 0,
      daipeiNum: 0,
      gaofangNum: 0,
      gfDjNum: 0,
      gfZjNum: 0,
      hospitalNum: 0,
      totalAmount: 0,
      vatFreight: 0,
      dateIndex: 0,
      daysIndex: 0,
      curDate: new Date(),
      minDate: new Date(2020, 1, 1),
      maxDate: new Date(2120, 12, 31),
      startDate: null,
      endDate: new Date(),
      totalBill: 0,
      showPicker: false,
      dateRange: ["近7天", "近30天", "今天", "昨天", "本月", "上月"],
      queryDate: "近7天",
      list: [],
      show: false,
      title: "标题",
      hospitalName: "",
    };
  },
  created() {},
  methods: {
    setDate() {
      if (this.dateIndex == 0) {
        this.startDate = this.dateFormat("YYYY-mm-dd", this.curDate);
      } else {
        this.endDate = this.dateFormat("YYYY-mm-dd", this.curDate);
      }
      this.showPicker = false;
      //开始查询数据
      this.getProject();
    },

    // 返回
    back() {
      this.$back();
    },
    goDetail(sysRecipeCode) {
      this.$router.push({
        name: "cookdetail",
        query: { way: "系统处方号", queryParam: sysRecipeCode },
      });
    },
    // 获取
    getProject() {
      const toast = this.$toast.loading({
        forbidClick: true,
        duration: 0,
        message: "加载中",
      });
      this.$axios
        .post("/data/reportRecipe", {
          startDate: this.startDate,
          endDate: this.endDate,
          userId: this.$user.id,
          hospitalName: this.hospitalName,
        })
        .then((res) => {
          toast.clear();

          // 医院数量
          this.hospitalNum = 0;
          // 总金额
          this.totalAmount = 0;
          // 总单数量
          this.totalBill = 0;

          this.vatFreight = 0;

          if (res.code == "ok") {
            this.list = res.data;

            // 计算待煎总数
            var daijianNum = 0;
            // 代配总数
            var daipeiNum = 0;
            // 膏方总数
            var gaofangNum = 0;
            // 膏方代煎
            var gfDjNum = 0;
            // 膏方自煎
            var gfZjNum = 0;
            this.hospitalNum = this.list.length;
            for (var i = 0; i < this.list.length; i++) {
              this.totalBill += this.list[i].totalNumber;
              this.list[i]._vatFreight = Math.floor(
                this.list[i].vatFreight || 0
              );
              this.vatFreight += this.list[i]._vatFreight;
              this.list[i]._vatFreight = (
                this.list[i]._vatFreight / 10000
              ).toFixed(4);
              var boilList = this.list[i].boilReports;
              this.list[i].gaofang = 0;
              this.list[i].qita = 0;
              this.list[i].daijian = 0;
              this.list[i].daipei = 0;
              for (var m = 0; m < boilList.length; m++) {
                var boilType = boilList[m].boilTypeName;
                const num = boilList[m].number;
                if (boilType == "代煎") {
                  daijianNum += num;
                  this.list[i].daijian = num;
                } else if (boilType == "代配") {
                  daipeiNum += num;
                  this.list[i].daipei = num;
                } else if (boilType == "膏方") {
                  gaofangNum += num;
                  this.list[i].gaofang += num;
                } else if (boilType == "膏方代煎") {
                  gfDjNum += num;
                  this.list[i].gfDjNum = (this.list[i].gfDjNum || 0) + num;
                } else if (boilType == "膏方自煎") {
                  gfZjNum += num;
                  this.list[i].gfZjNum = (this.list[i].gfZjNum || 0) + num;
                } else {
                  this.list[i].qita += num;
                }
              }
            }
            this.daijianNum = daijianNum;
            this.daipeiNum = daipeiNum;
            this.gaofangNum = gaofangNum;
            this.gfDjNum = gfDjNum;
            this.gfZjNum = gfZjNum;
            this.vatFreight = (this.vatFreight / 10000).toFixed(4);
          } else {
            this.$toast.fail("获取失败：" + res.msg);
          }
        })
        .catch((err) => {
          toast.clear();
        });
    },
    setRecentDays(daysIndex, days) {
      this.daysIndex = daysIndex;
      if (this.daysIndex == 0) {
        //今天
        var d = new Date();
        this.startDate = this.dateFormat("YYYY-mm-dd", d);
        this.endDate = this.dateFormat("YYYY-mm-dd", new Date());
      } else if (this.daysIndex == 1) {
        //昨天
        var d = new Date();
        d.setDate(d.getDate() - 1);
        this.startDate = this.dateFormat("YYYY-mm-dd", d);
        this.endDate = this.dateFormat("YYYY-mm-dd", d);
      } else {
        //近多少天
        var d = new Date();
        d.setDate(d.getDate() - days + 1);
        this.startDate = this.dateFormat("YYYY-mm-dd", d);
        this.endDate = this.dateFormat("YYYY-mm-dd", new Date());
      }

      this.getProject();
    },
    dateFormat(fmt, date) {
      if (!date) {
        return "";
      }
      if (typeof date === "string") {
        return date;
      }
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
    editDate(dateIndex, curDate) {
      this.showPicker = true;
      this.dateIndex = dateIndex;
      this.curDate = new Date(curDate);
    },
  },

  mounted() {
    //默认近7天
    this.setRecentDays(0, 0);
  },
};
</script>

<style lang="less" scoped>
.my_tag {
  width: 55px;
  text-align: center;
  display: inline-block;
  font-size: 13px;
  padding: 1px 3px 0px 3px;
  color: #888;
  border: 1px solid #888;
  border-radius: 15px;
}
.active {
  color: #1989fa;
  border: 1px solid #1989fa;
}

.dateField {
  border: 1px solid #bbb;
  stroke-width: 2px;
  border-radius: 3px;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 5px;
}

.one_report {
  margin-bottom: 15px;
}

.form_text {
  margin: 15px 0 30px;
  padding: 0 12px;
}

.form_btn {
  text-align: right;
}

.form_btn button {
  margin-left: 10px;
}

.one_report_data {
  margin-bottom: 20px;
  padding: 0 22px;
  div {
    line-height: 30px;
  }
}
.item_container {
  width: 100%;
  overflow: hidden;
}
.item_container span {
  display: block;
  float: left;
}
.color1 {
  color: #4e3011;
}
.color2 {
  color: #e6941a;
}
.color3 {
  color: #22aa3e;
}
</style>
<style lang="less">
.van-nav-bar .van-icon,
.van-nav-bar__text {
  color: #20a0ff;
}
</style>
